import axios from "axios";
export const Recuitmentapi = {
  recuitment: function (data) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/store-recruitment`,
      method: `POST`,
      data: data,
    });
  },
};
