import React from "react";
import Logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { Navbarapi } from "./Navbarapi";

const Navbar = () => {
  const [topbar, setTopbar] = useState({});
  useEffect(() => {
    Navbarapi.navbar()
      .then((res) => {
        console.log(res.data.data.contact, "contact us");
        let response = res.data.data.contact;
        setTopbar(response);
      })
      .catch((err) => {
        console.log(err, "error message");
      });
  }, []);
  return (
    <>
      <div id="top-bar" className="top-bar">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <ul className="top-info text-center text-md-left">
                <li>
                  <i className="fas fa-map-marker-alt" />
                  <p className="info-text">{topbar?.address}</p>
                </li>
              </ul>
            </div>
            {/*/ Top info end */}
            <div className="col-lg-4 col-md-4 top-social text-center text-md-right">
              <ul className="list-unstyled">
                <li>
                  <a title="Facebook" target="_blank" href={topbar?.facebook}>
                    <span className="social-icon">
                      <i className="fab fa-facebook-f" />
                    </span>
                  </a>
                  <a title="Twitter" href={topbar?.youtube} target="_blank">
                    <span className="social-icon">
                      <i className="fab fa-youtube" />
                    </span>
                  </a>
                  <a title="Instagram" href={topbar?.instagram} target="_blank">
                    <span className="social-icon">
                      <i className="fab fa-instagram" />
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            {/*/ Top social end */}
          </div>
          {/*/ Content row end */}
        </div>
        {/*/ Container end */}
      </div>
      {/*/ Topbar end */}
      {/* Header start */}
      <header id="header" className="header-one">
        <div className="bg-white">
          <div className="container">
            <div className="logo-area">
              <div className="row align-items-center">
                <div className="logo col-lg-3 text-center text-lg-left mb-3 mb-md-5 mb-lg-0">
                  <NavLink to="/" className="d-block">
                    <img loading="lazy" src={Logo} alt="Logo" />
                  </NavLink>
                </div>
                {/* logo end */}
                <div className="col-lg-9 header-right">
                  <ul className="top-info-box">
                    <li>
                      <div className="info-box">
                        <div className="info-box-content">
                          <p className="info-box-title">Call Us</p>
                          <p className="info-box-subtitle">
                            <a href="tel: {topbar.phone}"> {topbar?.phone}</a>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="info-box">
                        <div className="info-box-content">
                          <p className="info-box-title">Email Us</p>
                          <p className="info-box-subtitle">
                            <a href="{topbar.email}">{topbar?.email}</a>
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="last">
                      <div className="info-box last">
                        <div className="info-box-content">
                          <p className="info-box-title">Visit Us</p>
                          <p className="info-box-subtitle">{topbar?.address}</p>
                        </div>
                      </div>
                    </li>

                    {/* <li className="header-get-a-quote">
                      <a className="btn btn-primary" href="contact.html">
                        Get A Quote
                      </a>
                    </li> */}
                  </ul>
                  {/* Ul end */}
                </div>
                {/* header right end */}
              </div>
              {/* logo area end */}
            </div>
            {/* Row end */}
          </div>
          {/* Container end */}
        </div>
        <div className="site-navigation">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg navbar-dark p-0">
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target=".navbar-collapse"
                    aria-controls="navbar-collapse"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                  <div
                    id="navbar-collapse"
                    className="collapse navbar-collapse"
                  >
                    <ul className="nav navbar-nav mr-auto">
                      <li className="nav-item">
                        <NavLink to="/" className="nav-link">
                          Home
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/about" className="nav-link">
                          About Us
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/service-list" className="nav-link">
                          Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/client" className="nav-link">
                          Clients
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/jobs" className="nav-link">
                          Jobs
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/drop-cv" className="nav-link">
                          Drop a CV
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/recuitment-service" className="nav-link">
                          Recruitment Services
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/testimonial" className="nav-link">
                          Testimonials
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/blog-list" className="nav-link">
                          Blog
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink to="/contact" className="nav-link">
                          Contact Us
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              {/*/ Col end */}
            </div>
            {/*/ Row end */}
            {/* <div className="nav-search">
              <span id="search">
                <i className="fa fa-search" />
              </span>
            </div> */}
            {/* Search end */}
            <div className="search-block" style={{ display: "none" }}>
              <label htmlFor="search-field" className="w-100 mb-0">
                <input
                  type="text"
                  className="form-control"
                  id="search-field"
                  placeholder="Type what you want and enter"
                />
              </label>
              <span className="search-close">×</span>
            </div>
            {/* Site search end */}
          </div>
          {/*/ Container end */}
        </div>
        {/*/ Navigation end */}
      </header>
      {/*/ Header end */}
      {/* Button trigger modal */}
      {/* Modal */}
    </>
  );
};
export default Navbar;
