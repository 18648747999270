import React from "react";
import Job from "../../Components/Bodycomponents/Job/Job";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { Jobapi } from "./Jobapi";
import { useNavigate } from "react-router-dom";
import { Jobformapi } from "./Jobformapi";
import { ValidEmail } from "../../helpers/Validators";

const Jobpage = () => {
  const [alljob, setAlljob] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const navigate = useNavigate();
  const [validationMessage, setValidationMessage] = useState({
    name: null,
    email: null,
    subject: null,
    message: null,
  });

  const CloseModal = () => {
    document.querySelector("#close").click();
  };

  //   const timer = setTimeout(() => console.log('Initial timeout!'), 1000);
  // clearTimeout(timer);

  const [selectedjob, setSelectedjob] = useState(null);
  const [selectedtitle, setSelectedtitle] = useState(null);

  const submit = async (e) => {
    e.preventDefault();
    if (name === "") {
      setValidationMessage({
        name: "Name cannot be empty",
        email: null,
        subject: null,
        message: null,
      });
      return;
    } else if (email === "") {
      setValidationMessage({
        email: "Email cannot be empty",
        name: null,
        subject: null,
        message: null,
      });
      return;
    } else if (ValidEmail(email) === false) {
      setValidationMessage({
        email: "Invalid email address",
        name: null,
        subject: null,
        message: null,
      });
      return;
    } else if (subject === "") {
      setValidationMessage({
        subject: "subject cannot be empty",
        name: null,
        email: null,
        message: null,
      });
      return;
    } else if (message === "") {
      setValidationMessage({
        message: "message cannot be empty",
        name: null,
        email: null,
        subject: null,
      });
      return;
    } else {
      setValidationMessage({
        name: null,
        email: null,
        subject: null,
        message: null,
      });
      setLoading(true);
      await Jobformapi.apply({
        application_id: selectedjob,
        name: name,
        subject: subject,
        email: email,
        message: message,
      })
        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: "successfully sent",
              error: null,
            });

            setName("");
            setSubject("");
            setEmail("");
            setMessage("");
            setTimeout(() => {
              CloseModal();
            }, 2000);
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server Error",
          });
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  useEffect(() => {
    setLoading(true);
    Jobapi.getjob()
      .then((res) => {
        console.log(res.data.data.applications, "jobs");
        let response = res.data.data.applications;
        setAlljob(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h3 className="section-sub-title mb-4">Jobs</h3>
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              {alljob.length > 0 ? (
                alljob.map((getjob) => {
                  return (
                    <div className="col-lg-12 col-md-12">
                      <Job
                        title={getjob.title}
                        description={
                          getjob.description ? parse(getjob.description) : null
                        }
                        id={getjob.id}
                        setSelectedjob={setSelectedjob}
                        setSelectedtitle={setSelectedtitle}
                      />
                    </div>
                  );
                })
              ) : (
                <h2 className="comments">no jobs to show</h2>
              )}
            </div>
          )}

          {/*/ Content row end */}
        </div>
        {/* Conatiner end */}
      </section>
      <div
        className="modal fade"
        id="applyJob"
        tabIndex={-1}
        aria-labelledby="applyJobLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          {response.success ? (
            <div class="alert alert-primary" role="alert">
              your request has been sent
            </div>
          ) : null}

          {response.error ? (
            <div class="alert alert-danger" role="alert">
              {response.error}
            </div>
          ) : null}
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="applyJobLabel">
                {selectedtitle}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form id="contact-form" action="#" method="post" role="form">
                <div className="error-container" />
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control form-control-name"
                        name="name"
                        id="name"
                        placeholder
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[A-Z a-z]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        style={
                          validationMessage.name
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                    </div>

                    {validationMessage.name ? (
                      <span className="error error-message">
                        {validationMessage.name}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control form-control-email"
                        name="email"
                        id="email"
                        placeholder
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={
                          validationMessage.email
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                    </div>

                    {validationMessage.email ? (
                      <span className="error error-message">
                        {validationMessage.email}
                      </span>
                    ) : null}
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        className="form-control form-control-subject"
                        name="subject"
                        id="subject"
                        placeholder
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        style={
                          validationMessage.subject
                            ? {
                                border: "1px solid red",
                              }
                            : null
                        }
                      />
                    </div>

                    {validationMessage.subject ? (
                      <span className="error error-message">
                        {validationMessage.subject}
                      </span>
                    ) : null}
                  </div>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className="form-control form-control-message"
                    name="message"
                    id="message"
                    placeholder
                    rows={10}
                    required
                    defaultValue={""}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={
                      validationMessage.message
                        ? {
                            border: "1px solid red",
                          }
                        : null
                    }
                  />
                </div>

                {validationMessage.message ? (
                  <span className="error error-message">
                    {validationMessage.message}
                  </span>
                ) : null}
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="text-right">
                    <br />
                    <button
                      className="btn btn-primary solid blank"
                      type="submit"
                      onClick={submit}
                    >
                      Apply for Job
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Jobpage;
