import React from "react";
import Project1 from "../../assets/images/projects/project1.jpg";
import Project2 from "../../assets/images/projects/project2.jpg";
import Slider from "react-slick";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { Serviceapi } from "./Serviceapi";

const Servicedetail = () => {
  const params = useParams();
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  useEffect(() => {
    setLoading(true);
    Serviceapi.servicedetail(params.slug)
      .then((res) => {
        console.log("service detail", res.data.data);
        let response = res.data.data;
        setDetail(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, [params.slug]);

  console.log("params ko slug", params.slug);
  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                <div className="content-inner-page">
                  <h2 className="column-title mrt-0">{detail.title}</h2>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        {detail?.description ? parse(detail.description) : null}
                      </p>
                    </div>
                    {/* col end */}
                  </div>
                  {/* 1st row end*/}
                  <div className="gap-40" />
                  <div className="page-slider" id="page-slider">
                    <Slider {...settings}>
                      {detail?.image?.map((sliderimage) => {
                        return (
                          <div className="item">
                            <img
                              src={sliderimage}
                              alt="project-slider-image"
                              className="img-fluid"
                            />
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                  <div className="gap-40" />
                  {/* <div className="call-to-action classic">
                  <div className="row align-items-center">
                    <div className="col-md-8 text-center text-md-left">
                      <div className="call-to-action-text">
                        <h3 className="action-title">
                          Interested with this service.
                        </h3>
                      </div>
                    </div>
                   
                    <div className="col-md-4 text-center text-md-right mt-3 mt-md-0">
                      <div className="call-to-action-btn">
                        <a className="btn btn-primary" href="#">
                          Get a Quote
                        </a>
                      </div>
                    </div>
                   
                  </div>
               
                </div> */}
                  {/* Action end */}
                </div>
              )}
            </div>

            {/* Content Col end */}
          </div>
          {/* Main row end */}
        </div>
        {/* Conatiner end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Servicedetail;
