import axios from "axios";
export const Navbarapi = {
  navbar: function () {
    // console.log("Called");
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-contact`,
      method: `GET`,
    });
  },
};
