import axios from "axios";
export const Serviceapi = {
  servicelist: function () {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-services`,
      method: `GET`,
    });
  },
  servicedetail: function (slug) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-service/${slug}`,
      method: `GET`,
    });
  },
};
