import React from "react";
import { useEffect, useState } from "react";

import { Blogapi } from "./Blogapi";
import parse from "html-react-parser";

import Blogcomp from "../../Components/Bodycomponents/Blogs/Blogcomp";

function Bloglist() {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [allPages, setAllPage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const handlePage = (e, value) => {
    e.preventDefault();
    setPageNumber(value);
  };
  useEffect(() => {
    setLoading(true);
    Blogapi.blogget(pageNumber)
      .then((res) => {
        console.log(res.data.data.blogs.data, "blog");
        let response = res.data.data.blogs.data;
        setBlog(response);
        setPagination(res.data.data.blogs);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, [pageNumber]);

  console.log(allPages);

  useEffect(() => {
    if (pagination) {
      let res = [];
      for (
        let i = 0;
        i < Math.ceil(pagination.total / pagination.per_page);
        i++
      ) {
        res.push(i + 1);
      }

      setAllPage(res);
    }
  }, [pagination]);
  return (
    <>
      <div className="row text-center mt-4">
        <div className="col-12">
          <h3 className="section-sub-title mb-4">Our Blogs</h3>
        </div>
      </div>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden"></span>
          </div>
        </div>
      ) : (
        <section id="main-container" className="main-container">
          <div className="container">
            <div className="row">
              {blog.length > 0 ? (
                blog.map((bloglist) => {
                  return (
                    <div className="col-lg-6 mb-5 mb-lg-0">
                      <div className="post">
                        <Blogcomp
                          image={bloglist.image}
                          date={bloglist.created_at?.split("T")[0]}
                          title={bloglist.title}
                          description={
                            bloglist.description
                              ? parse(bloglist.description)
                              : null
                          }
                          slug={bloglist.slug}
                        />
                        {/* post-body end */}
                      </div>
                      {/* 1st post end */}
                    </div>
                  );
                })
              ) : (
                <h2 className="comments">no any blog to show</h2>
              )}

              {/* Content Col end */}
              <div className="col-lg-12 mb-5 mb-lg-0">
                <nav className="paging" aria-label="Page navigation example">
                  <ul className="pagination">
                    {pagination?.current_page === pageNumber ? null : (
                      <li
                        className="page-item"
                        onClick={() => setPageNumber(pageNumber - 1)}
                      >
                        <a className="page-link" href="#">
                          <i className="fas fa-angle-double-left" />
                        </a>
                      </li>
                    )}

                    {allPages.slice(0, 5).map((item) => (
                      <li
                        className="page-item"
                        onClick={(e) => handlePage(e, item)}
                      >
                        <a
                          className="page-link"
                          style={
                            item == pageNumber
                              ? { backgroundColor: `#ED1B26`, color: `#fff` }
                              : null
                          }
                          href="#"
                        >
                          {item}
                        </a>
                      </li>
                    ))}
                    {pagination?.last_page === pageNumber ? null : (
                      <li
                        className="page-item"
                        onClick={() => setPageNumber(pageNumber + 1)}
                      >
                        <a className="page-link" href="#">
                          <i className="fas fa-angle-double-right" />
                        </a>
                      </li>
                    )}
                  </ul>
                </nav>
              </div>
              {/* Content Col end */}
            </div>
            {/* Main row end */}
          </div>
          {/* Container end */}
        </section>
      )}

      {/* Main container end */}
    </>
  );
}

export default Bloglist;
