import axios from "axios";
export const Jobformapi = {
  apply: function (data) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/store-applicant`,
      method: `POST`,
      data: data,
    });
  },
};
