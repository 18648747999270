import axios from "axios";
export const Cvapi = {
  getcv: function (data) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/store-resume`,
      method: `POST`,
      data: data,
    });
  },
};
