import React from "react";
import Client1 from "../assets/images/clients/client1.png";
import Client2 from "../assets/images/clients/client2.png";
import Client3 from "../assets/images/clients/client3.png";
import Client4 from "../assets/images/clients/client4.png";
import Client5 from "../assets/images/clients/client5.png";
import Client6 from "../assets/images/clients/client6.png";
import { Clientapi } from "../Components/Bodycomponents/Clients/Clientapi";
import { useState, useEffect } from "react";

const Clientpage = () => {
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Clientapi.getclient()
      .then((res) => {
        console.log(res.data.data.partners, "clients");
        let response = res.data.data.partners;
        setClient(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <section className="content">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h3 className="section-sub-title mb-4">Our Clients</h3>
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 mt-5 mt-lg-0">
                <div className="row all-clients">
                  {client.length > 0 ? (
                    client?.map((allclient) => {
                      return (
                        <div className="col-lg-4 col-md-4">
                          <figure className="clients-logo">
                            <a href="#!">
                              <img
                                loading="lazy"
                                className="img-fluid"
                                src={allclient.image}
                                alt="clients-logo"
                              />
                              <h6>{allclient.title}</h6>
                            </a>
                          </figure>
                        </div>
                      );
                    })
                  ) : (
                    <h2 className="comments">no any client to show</h2>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
};
export default Clientpage;
