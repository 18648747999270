import React from "react";
import { useState, useEffect } from "react";
import Testimonial1 from "../../../assets/images/clients/download (1).jpg";
import { Whoweareapi } from "./Whoweareapi";
import parse from "html-react-parser";
import { Button } from "bootstrap";
import { Link } from "react-router-dom";
const Whoweare = () => {
  const [message, setMessage] = useState({});
  const [about, setAbout] = useState({});
  useEffect(() => {
    Whoweareapi.whoweare()
      .then((res) => {
        console.log(res.data.data.messages, "who we are");
        let response = res.data.data.messages;
        setMessage(response);
      })
      .catch((err) => {
        console.log(err, "error message");
      });
    Whoweareapi.about()
      .then((res) => {
        console.log(res.data.data.about, "who we are");
        let response = res.data.data.about;
        setAbout(response);
      })
      .catch((err) => {
        console.log(err, "error message");
      });
  }, []);
  return (
    <>
      <section className="content" id="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h3 className="column-title">Message from Chairman</h3>
              <div className="item">
                <div className="quote-item">
                  <span className="quote-text">
                    {message?.message ? parse(message?.message) : null}
                  </span>
                  <div className="quote-item-footer">
                    <img
                      loading="lazy"
                      className="testimonial-thumb"
                      src={message?.image}
                      alt="testimonial"
                    />
                    <div className="quote-item-info">
                      <h3 className="quote-author">{message?.name}</h3>
                      <span className="quote-subtext">
                        {message?.designation}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Quote item end */}
              </div>
              {/*/ Item 1 end */}
              {/*/ Testimonial carousel end*/}
            </div>
            {/* Col end */}
            <div className="col-lg-6">
              <h3 className="column-title">Who We Are</h3>
              <p>
                {about?.description
                  ? parse(about?.description?.substr(0, 500))
                  : null}
              </p>
              <Link to="/about">
                <button className="btn btn-primary">Read more</button>
              </Link>
            </div>
            {/* Col end */}
          </div>
          {/*/ Content row end */}
        </div>
        {/*/ Container end */}
      </section>
    </>
  );
};
export default Whoweare;
