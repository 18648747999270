import React from "react";
import { NavLink } from "react-router-dom";

import { Blogapi } from "./Blogapi";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";

const Blogdetail = () => {
  const params = useParams();
  const [detail, setDetail] = useState({});
  const [relatedblog, setRelatedblog] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Blogapi.getblogdetail(params.slug)
      .then((res) => {
        console.log(res.data.data.blog, "blog detail");
        let response = res.data.data.blog;
        let response2 = res.data.data.relatedBlogs;
        setDetail(response);
        setRelatedblog(response2);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, [params.slug]);

  console.log(relatedblog, "relatedblog");

  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-12 mb-5 mb-lg-0">
                <div className="post-content post-single">
                  <div className="post-body">
                    <div className="entry-header">
                      <div className="post-meta">
                        <span className="post-meta-date">
                          <i className="far fa-calendar" />{" "}
                          {detail.created_at?.split("T")[0]}
                        </span>
                      </div>
                      <h2 className="entry-title">{detail.title}</h2>
                    </div>
                    {/* header end */}
                  </div>
                  <div className="post-media post-image">
                    <img
                      loading="lazy"
                      src={detail.image}
                      className="img-fluid"
                      alt="post-image"
                    />
                  </div>
                  <div className="post-body">
                    <div className="entry-content">
                      <p>
                        {detail.description ? parse(detail.description) : null}
                      </p>
                    </div>
                    <div className="tags-area d-flex align-items-center justify-content-between">
                      <div className="share-items">
                        <ul className="post-social-icons list-unstyled">
                          <li className="social-icons-head">Share:</li>
                          <li>
                            <a href="#">
                              <i className="fab fa-facebook-f" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  {/* post-body end */}
                </div>
              </div>

              <div className="col-lg-12">
                <div className="sidebar">
                  <div className="widget recent-posts">
                    <h3 className="widget-title">Recent Posts</h3>
                    <ul className="list-unstyled">
                      {relatedblog.map((relatedb) => {
                        return (
                          <li className="d-flex align-items-center">
                            <div className="posts-thumb">
                              <NavLink to={`/blog-detail/${relatedb.slug}`}>
                                <img
                                  loading="lazy"
                                  alt="img"
                                  src={relatedb.image}
                                />
                              </NavLink>
                            </div>

                            <div className="post-info">
                              <h4 className="entry-title">
                                <NavLink to={`/blog-detail/${relatedb.slug}`}>
                                  {relatedb.title}
                                </NavLink>
                              </h4>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                {/* Sidebar end */}
              </div>
            </div>
          )}
          {/* Main row end */}
        </div>
        {/* Conatiner end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Blogdetail;
