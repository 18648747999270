import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ValidContact, ValidEmail } from "../../helpers/Validators";
import { Recuitmentapi } from "./Recuitmentapi";
const Recuitmentpage = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });

  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();
    if (
      name === "" ||
      company === "" ||
      email === "" ||
      phone === "" ||
      description === ""
    ) {
      setResponse({
        success: null,
        error: "Empty field detected",
      });
    } else if (ValidEmail(email) === false) {
      setResponse({
        success: null,
        error: "Invalid Email",
      });
    } else if (ValidContact(phone) === false) {
      setResponse({
        success: null,
        error: "Invalid phone number",
      });
    } else {
      setLoading(true);
      await Recuitmentapi.recuitment({
        name: name,
        company: company,
        email: email,
        phone: phone,
        description: description,
      })
        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: "successfully sent",
              error: null,
            });

            setName("");
            setCompany("");
            setEmail("");
            setPhone("");
            setDescription("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server Error",
          });
        });
      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h3 className="column-title">Recruitment Service</h3>
              <p>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Asperiores animi molestias corporis hic dolorum et veniam
                impedit quod dicta eum excepturi alias voluptate adipisci minima
                quis, iure autem sit non.
              </p>
              {/* contact form works with formspree.io  */}
              {/* contact form activation doc: https://docs.themefisher.com/constra/contact-form/ */}
              {response.success ? (
                <div class="alert alert-primary" role="alert">
                  your request has been sent
                </div>
              ) : null}

              {response.error ? (
                <div class="alert alert-danger" role="alert">
                  {response.error}
                </div>
              ) : null}
              <form id="contact-form" action="#" method="post" role="form">
                <div className="error-container" />
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Name of person</label>
                      <input
                        className="form-control form-control-name"
                        name="name"
                        id="name"
                        placeholder
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onKeyPress={(event) => {
                          if (!/[A-Z a-z]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Name of company</label>
                      <input
                        className="form-control form-control-name"
                        name="name"
                        id="name"
                        placeholder
                        type="text"
                        required
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control form-control-email"
                        name="email"
                        id="email"
                        placeholder
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        className="form-control form-control-subject"
                        name="subject"
                        id="subject"
                        placeholder
                        required
                        type="tel"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Type of recruitment looking for</label>
                  <textarea
                    className="form-control form-control-message"
                    name="message"
                    id="message"
                    placeholder
                    rows={10}
                    required
                    defaultValue={""}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
                {loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="text-right">
                    <br />
                    <button
                      className="btn btn-primary solid blank"
                      type="submit"
                      onClick={submit}
                    >
                      Send Request
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          {/* Content row */}
        </div>
        {/* Conatiner end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Recuitmentpage;
