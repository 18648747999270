import React from "react";

const Job = ({ title, description, id, setSelectedjob, setSelectedtitle }) => {
  return (
    <>
      <div className="ts-pricing-box ts-pricing-featured">
        <div className="ts-pricing-header">
          <h2 className="ts-pricing-price">
            <span className="currency">{title}</span>
            <small>Job description given below</small>
          </h2>
        </div>
        {/* Pricing header */}
        <div className="ts-pricing-features">
          <p>{description}</p>
        </div>
        {/* Features end */}
        <div className="plan-action">
          <a
            href="#"
            className="btn btn-primary"
            data-toggle="modal"
            data-target="#applyJob"
            onClick={() => {
              setSelectedjob(id);
              setSelectedtitle(title);
            }}
          >
            Apply Now
          </a>
        </div>
      </div>
      {/* Main container end */}
    </>
  );
};
export default Job;
