import axios from "axios";
export const Contactapi = {
  contact: function () {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-contact`,
      method: `GET`,
    });
  },
  contactpost: function (data) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/store-feedback`,
      method: `POST`,
      data: data,
    });
  },
};
