import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Testimonialapi } from "../Testimonial/Testimonialapi";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

const Testimonial = () => {
  const [alltestimonial, setAlltestimonial] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Testimonialapi.gettestimonial()
      .then((res) => {
        console.log(res.data.data.testimonials, "clients");
        let response = res.data.data.testimonials;
        setAlltestimonial(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  var settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    speed: 600,
    arrows: false,
  };
  return (
    <>
      <section className="content" id="main-container">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h3 className="column-title">Testimonials</h3>
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                <Slider {...settings}>
                  {alltestimonial.length > 0 ? (
                    alltestimonial.map((test) => {
                      return (
                        <div className="item">
                          <div className="quote-item quote-border mt-5">
                            <div className="quote-text-border">
                              {test.description
                                ? parse(test.description)
                                : null}
                            </div>
                            <div className="quote-item-footer">
                              <img
                                loading="lazy"
                                className="testimonial-thumb"
                                src={test.image}
                                alt="testimonial"
                              />
                              <div className="quote-item-info">
                                <h3 className="quote-author">{test.title}</h3>
                                <span className="quote-subtext">
                                  {test.designation}
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* Quote item end */}
                        </div>
                      );
                    })
                  ) : (
                    <h2 className="comments">no any testimonial to show</h2>
                  )}

                  {/*/ Item 1 end */}
                </Slider>
              )}
            </div>
            {/* Col end */}
          </div>
        </div>
        {/*/ Container end */}
      </section>
    </>
  );
};
export default Testimonial;
