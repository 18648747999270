import React from "react";
import { Testimonialapi } from "../Components/Bodycomponents/Testimonial/Testimonialapi";
import { useEffect, useState } from "react";
import parse from "html-react-parser";

const Testimonialpage = () => {
  const [alltestimonial, setAlltestimonial] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Testimonialapi.gettestimonial()
      .then((res) => {
        console.log(res.data.data.testimonials, "clients");
        let response = res.data.data.testimonials;
        setAlltestimonial(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h3 className="section-sub-title mb-4">What People Said</h3>
            </div>
          </div>
          {/*/ Title row end */}
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              {alltestimonial.length > 0 ? (
                alltestimonial.map((test) => {
                  return (
                    <div className="col-lg-4 col-md-6">
                      <div className="quote-item quote-border mt-5">
                        <div className="quote-text-border">
                          {test.description ? parse(test.description) : null}
                        </div>
                        <div className="quote-item-footer">
                          <img
                            loading="lazy"
                            className="testimonial-thumb"
                            src={test.image}
                            alt="testimonial"
                          />
                          <div className="quote-item-info">
                            <h3 className="quote-author">{test.title}</h3>
                            <span className="quote-subtext">
                              {test.designation}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* Quote item end */}
                    </div>
                  );
                })
              ) : (
                <h2 className="comments">no any testimonial to show</h2>
              )}
            </div>
          )}

          {/* Content row end */}
        </div>
        {/* Container end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Testimonialpage;
