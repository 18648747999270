import React from "react";
import Team1 from "../../../assets/images/team/team1.jpg";
import Team2 from "../../../assets/images/team/team2.jpg";
import Team3 from "../../../assets/images/team/team3.jpg";
import Team4 from "../../../assets/images/team/team4.jpg";
import Team5 from "../../../assets/images/team/team5.jpg";
import Team6 from "../../../assets/images/team/team6.jpg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Clientapi } from "./Clientapi";
import { useState, useEffect } from "react";

const Clients = () => {
  const [client, setClient] = useState([]);
  const [loading, setLoading] = useState("");
  useEffect(() => {
    setLoading(true);
    Clientapi.getclient()
      .then((res) => {
        console.log(res.data.data.partners, "clients");
        let response = res.data.data.partners;
        setClient(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <section id="ts-team" className="ts-team">
        <div className="container">
          <div className="row text-center">
            <div className="col-lg-12">
              <h2 className="section-title">Quality Service</h2>
              <h3 className="section-sub-title">Our Clients</h3>
            </div>
          </div>
          {/*/ Title row end */}
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              {client?.length > 0 ? (
                <OwlCarousel
                  className="owl-theme"
                  margin={10}
                  dots={false}
                  nav
                  responsive={{
                    0: {
                      items: 2,
                    },
                    750: {
                      items: 2,
                    },
                    1000: {
                      items: 4,
                    },
                  }}
                >
                  {client?.map((allclient) => {
                    return (
                      <div className="item">
                        <div className="ts-team-wrapper">
                          <div className="team-img-wrapper">
                            <img
                              loading="lazy"
                              className="w-100"
                              src={allclient.image}
                              alt="team-img"
                            />
                          </div>
                          <div className="ts-team-content">
                            <h3 className="ts-name"> {allclient.title} </h3>
                            <p className="ts-designation">
                              {" "}
                              {allclient.partner_type}
                            </p>
                            <div className="team-social-icons">
                              <a target="_blank" href={allclient.url}>
                                Visit Website
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </OwlCarousel>
              ) : (
                <div className="clientcomment">
                  <h2 className="comments text-center">
                    no any clients to show
                  </h2>
                </div>
              )}
            </div>
          )}
        </div>
        {/*/ Content row end */}

        {/*/ Container end */}
      </section>
    </>
  );
};
export default Clients;
