import React from "react";
import { useEffect, useState } from "react";
import Blogcomp from "./Blogcomp";
import { Blogapi } from "../../../Pages/Blog/Blogapi";
import parse from "html-react-parser";

const Blogs = () => {
  const [blog, setBlog] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Blogapi.blogget()
      .then((res) => {
        console.log("blog dATA is", res.data.data.blogs.data);
        let response = res.data.data?.blogs?.data;
        setBlog(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              {blog.length > 0
                ? blog.slice(0, 3).map((getblog) => {
                    return (
                      <div className="col-lg-4 mb-5 mb-lg-0">
                        <div className>
                          <Blogcomp
                            image={getblog.image}
                            date={getblog.created_at?.split("T")[0]}
                            title={getblog.title}
                            description={
                              getblog.description
                                ? parse(getblog.description)
                                : null
                            }
                            slug={getblog.slug}
                          />
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          )}
        </div>
        {/* Container end */}
      </section>
    </>
  );
};
export default Blogs;
