import React from "react";
import { NavLink } from "react-router-dom";

const Blogcomp = ({ image, date, title, description, slug }) => {
  return (
    <>
      <div className="post-media post-image">
        <img
          loading="lazy"
          src={image ? image : null}
          className="img-fluid"
          alt="post-image"
        />
      </div>
      <div className="post-body">
        <div className="entry-header">
          {date ? (
            <div className="post-meta">
              <span className="post-meta-date">
                <i className="far fa-calendar" /> {date}
              </span>
            </div>
          ) : null}
          <h2 className="entry-title">
            <NavLink to={`/blog-detail/${slug}`}>{title}</NavLink>
          </h2>
        </div>
        {/* header end */}
        <div className="home-entry-content">
          <p>{description}</p>
        </div>
        <div className="post-footer">
          <NavLink to={`/blog-detail/${slug}`} className="btn btn-primary">
            Continue Reading
          </NavLink>
        </div>
      </div>
    </>
  );
};
export default Blogcomp;
