import React from "react";

import { useState, useEffect } from "react";
import { Contactapi } from "./Contactapi";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import { ValidEmail } from "../../helpers/Validators";

const Contact = () => {
  const [contact, setContact] = useState({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [buttonloader, setButtonloader] = useState(false);

  useEffect(() => {
    setLoading(true);
    Contactapi.contact()
      .then((res) => {
        console.log(res.data.data.contact, "contact us");
        let response = res.data.data.contact;
        setContact(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState({
    success: null,
    error: null,
  });
  const navigate = useNavigate();
  const submit = async (e) => {
    e.preventDefault();

    setButtonloader(true);
    if (name === "" || email === "" || subject === "" || message === "") {
      setResponse({
        success: null,
        error: "Empty field detected",
      });
      setButtonloader(false);
    } else if (ValidEmail(email) === false) {
      setResponse({
        success: null,
        error: "Invalid Email",
      });
    } else {
      setLoading(true);
      await Contactapi.contactpost({
        name: name,
        email: email,
        subject: subject,
        message: message,
      })

        .then((res) => {
          if (res.data.status === true) {
            setResponse({
              success: "successfully sent",
              error: null,
            });

            setName("");
            setEmail("");
            setSubject("");
            setMessage("");
          } else {
            setResponse({
              success: null,
              error: res.data.message,
            });
          }
        })
        .catch((error) => {
          setResponse({
            success: null,
            error: "Server Error",
          });
        });
      setButtonloader(false);

      setLoading(false);
    }
    setTimeout(() => {
      setResponse({
        success: null,
        error: null,
      });
    }, 5000);
  };

  return (
    <>
      <section id="main-container" className="main-container">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="ts-service-box-bg text-center h-100">
                <span className="ts-service-icon icon-round">
                  <i className="fas fa-map-marker-alt mr-0" />
                </span>
                <div className="ts-service-box-content">
                  <h4>Visit Our Office</h4>
                  <p>{contact.address}</p>
                </div>
              </div>
            </div>
            {/* Col 1 end */}
            <div className="col-md-4">
              <div className="ts-service-box-bg text-center h-100">
                <span className="ts-service-icon icon-round">
                  <i className="fa fa-envelope mr-0" />
                </span>
                <div className="ts-service-box-content">
                  <h4>Email Us</h4>
                  <p>{contact.email}</p>
                </div>
              </div>
            </div>
            {/* Col 2 end */}
            <div className="col-md-4">
              <div className="ts-service-box-bg text-center h-100">
                <span className="ts-service-icon icon-round">
                  <i className="fa fa-phone-square mr-0" />
                </span>
                <div className="ts-service-box-content">
                  <h4>Call Us</h4>
                  <p>(+977)-{contact.phone}</p>
                </div>
              </div>
            </div>
            {/* Col 3 end */}
          </div>
          {/* 1st row end */}

          <div className="gap-60" />
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="google-map">
              <div
                id="map"
                className="map"
                data-latitude="40.712776"
                data-longitude="-74.005974"
                data-marker={contact.google_map}
                data-marker-name="Constra"
              />
            </div>
          )}
          <div className="gap-40" />
          <div className="row">
            <div className="col-md-12">
              <h3 className="column-title">We love to hear</h3>
              {/* contact form works with formspree.io  */}
              {/* contact form activation doc: https://docs.themefisher.com/constra/contact-form/ */}
              <form id="contact-form" action="#" method="post" role="form">
                <div className="error-container" />
                {response.success ? (
                  <div class="alert alert-primary" role="alert">
                    your request has been sent
                  </div>
                ) : null}

                {response.error ? (
                  <div class="alert alert-danger" role="alert">
                    {response.error}
                  </div>
                ) : null}
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Name</label>
                      <input
                        className="form-control form-control-name"
                        name="name"
                        id="name"
                        placeholder
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control form-control-email"
                        name="email"
                        id="email"
                        placeholder
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Subject</label>
                      <input
                        className="form-control form-control-subject"
                        name="subject"
                        id="subject"
                        placeholder
                        required
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>Message</label>
                  <textarea
                    className="form-control form-control-message"
                    name="message"
                    id="message"
                    placeholder
                    rows={10}
                    required
                    defaultValue={""}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                {buttonloader ? (
                  <div className="text-right">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden"></span>
                    </div>
                  </div>
                ) : (
                  <div className="text-right">
                    <br />
                    <button
                      className="btn btn-primary solid blank"
                      type="submit"
                      onClick={submit}
                    >
                      Send Message
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
          {/* Content row */}
        </div>
        {/* Conatiner end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Contact;
