import axios from "axios";
export const Blogapi = {
  blogget: function (pageNumber) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-all-blog`,
      method: `GET`,
      params: {
        page: pageNumber,
      },
    });
  },
  getblogdetail: function (slug) {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/view-blog/${slug}`,
      method: `GET`,
    });
  },
};
