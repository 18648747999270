import React from "react";
import { Homecarouselapi } from "./Homecarouselapi";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
const Homecarousel = () => {
  const [carousel, setCarousel] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Homecarouselapi.carousel()
      .then((res) => {
        console.log("error here", res.data.data.carousels);
        let response = res.data.data.carousels;
        setCarousel(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="banner-carousel banner-carousel-1 mb-0">
        <Carousel>
          {carousel?.map((getimage) => {
            return (
              <div
                className="banner-carousel-item"
                style={{
                  backgroundImage: `url(${
                    getimage?.image ? getimage?.image : null
                  })`,
                }}
              >
                <div className="slider-content">
                  <div className="container h-100">
                    <div className="row align-items-center h-100">
                      <div className="col-md-12 text-center">
                        <h2
                          className="slide-title"
                          data-animation-in="slideInLeft"
                        >
                          {getimage?.title ? parse(getimage?.title) : null}
                        </h2>
                        <h3
                          className="slide-sub-title"
                          data-animation-in="slideInRight"
                        >
                          {getimage?.description
                            ? parse(getimage?.description)
                            : null}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
export default Homecarousel;
