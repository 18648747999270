import React from "react";

import Services from "../../Components/Bodycomponents/Services/Services";
import { Serviceapi } from "./Serviceapi";
import { useState, useEffect } from "react";
const Servicelist = () => {
  const [service, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    Serviceapi.servicelist()
      .then((res) => {
        console.log("services are", res.data.data.services[0].image[0]);
        let response = res.data.data.services;
        setService(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);

  return (
    <>
      <section id="main-container" className="main-container pb-2">
        <div className="container">
          <div className="row text-center">
            <div className="col-12">
              <h3 className="section-sub-title mb-4">Our Services</h3>
            </div>
          </div>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden"></span>
              </div>
            </div>
          ) : (
            <div className="row">
              {service.length > 0 ? (
                service?.map((getservice) => {
                  return (
                    <div className="col-lg-4 col-md-6 mb-5">
                      <Services
                        image={getservice.image[0] ? getservice.image[0] : null}
                        title={getservice.title ? getservice.title : null}
                        icon={getservice.icon ? getservice.icon : null}
                        slug={getservice.slug}
                      />
                    </div>
                  );
                })
              ) : (
                <h2 className="comments">no service to show</h2>
              )}
            </div>
          )}
        </div>
        {/* Conatiner end */}
      </section>
      {/* Main container end */}
    </>
  );
};
export default Servicelist;
