import React from "react";
import Blogs from "../Components/Bodycomponents/Blogs/Blogs";
import Clients from "../Components/Bodycomponents/Clients/Clients";
import Homecarousel from "../Components/Bodycomponents/Homecarousel/Homecarousel";
import Services from "../Components/Bodycomponents/Services/Services";
import Testimonial from "../Components/Bodycomponents/Testimonial/Testimonial";
import Whoweare from "../Components/Bodycomponents/Whoweare/Whoweare";
import Servicelist from "./Service/Servicelist";

export const Home = () => {
  return (
    <>
      <Homecarousel />
      <Servicelist />
      <Whoweare />
      <Clients />
      <Testimonial />
      <Blogs />
    </>
  );
};
