import React from "react";
import Image1 from "../../../assets/images/services/service1.jpg";
import Icon1 from "../../../assets/images/icon-image/service-icon1.png";
import Image2 from "../../../assets/images/services/service2.jpg";
import Icon2 from "../../../assets/images/icon-image/service-icon2.png";
import Image3 from "../../../assets/images/services/service3.jpg";
import Icon3 from "../../../assets/images/icon-image/service-icon3.png";
import { Link } from "react-router-dom";

const Services = ({ image, title, icon, slug }) => {
  return (
    <>
      <div className="ts-service-box">
        <div className="ts-service-image-wrapper">
          <img
            loading="lazy"
            className="w-100"
            src={image}
            alt="service-image"
          />
        </div>
        <div className="d-flex">
          <div className="ts-service-box-img">
            <img loading="lazy" src={icon} alt="service-icon" />
          </div>
          <div className="ts-service-info">
            <h3 className="service-box-title">
              <Link to={`/service-detail/${slug}`}>{title}</Link>
            </h3>
            <Link
              className="learn-more d-inline-block"
              to={`/service-detail/${slug}`}
              aria-label="service-details"
            >
              <i className="fa fa-caret-right" /> Learn more
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
export default Services;
