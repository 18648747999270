import React from "react";
import Logo from "../../assets/images/logo.png";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import parse from "html-react-parser";
import { Footerapi } from "./Footerapi";

const Footer = () => {
  const [footer, setFooter] = useState({});
  const [loading, setLoading] = useState(false);
  const [footericon, setFootericon] = useState({});
  useEffect(() => {
    setLoading(true);
    Footerapi.workinghr()
      .then((res) => {
        console.log(res.data.data.about, "working hours");
        let response = res.data.data.about;
        setLoading(false);
        setFooter(response);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  useEffect(() => {
    setLoading(true);
    Footerapi.navbar()
      .then((res) => {
        console.log(res.data.data.contact, "footer icons");
        let response = res.data.data.contact;
        setLoading(false);
        setFootericon(response);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <footer id="footer" className="footer bg-overlay">
        <div className="footer-main">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-4 col-md-6 footer-widget footer-about">
                <h3 className="widget-title">Bhandari Brothers</h3>
                <img
                  loading="lazy"
                  width="200px"
                  className="footer-logo"
                  src={Logo}
                  alt="bhandari brothers"
                />
                <div className="footer-social">
                  <ul>
                    <li>
                      <a href={footericon?.facebook} aria-label="Facebook">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a href={footericon?.youtube} aria-label="Youtube">
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                    <li>
                      <a href={footericon?.instagram} aria-label="Instagram">
                        <i className="fab fa-instagram" />
                      </a>
                    </li>
                  </ul>
                </div>
                {/* Footer social end */}
              </div>
              {/* Col end */}
              {loading ? (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                  </div>
                </div>
              ) : (
                <div className="col-lg-4 col-md-6 footer-widget mt-5 mt-md-0">
                  <h3 className="widget-title">Working Hours</h3>
                  <div className="working-hours">
                    <p>
                      {" "}
                      {footer?.about_working_hour
                        ? parse(footer?.about_working_hour)
                        : "no data"}
                    </p>
                    <br />
                    <br /> Monday - Friday:{" "}
                    <span className="text-right">
                      {footer?.normal_day_hour}
                    </span>
                    <br /> Saturday:{" "}
                    <span className="text-right">{footer?.weekend_hour}</span>
                    <br /> Sunday and holidays:{" "}
                    <span className="text-right">{footer?.holiday_hour}</span>
                  </div>
                </div>
              )}
              {/* Col end */}
              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0 footer-widget">
                <h3 className="widget-title">Quick Links</h3>
                <ul className="list-arrow">
                  <li>
                    <NavLink to="/about">About</NavLink>
                  </li>
                  <li>
                    <NavLink to="/service-list">Services</NavLink>
                  </li>
                  <li>
                    <NavLink to="/drop-cv">Drop a CV </NavLink>
                  </li>
                  <li>
                    <NavLink to="/jobs">Jobs</NavLink>
                  </li>
                  <li>
                    <NavLink to="/recuitment-service">
                      Recruiment Services
                    </NavLink>
                  </li>
                </ul>
              </div>
              {/* Col end */}
            </div>
            {/* Row end */}
          </div>
          {/* Container end */}
        </div>
        {/* Footer main end */}
        <div className="copyright">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-12">
                <div className="copyright-info text-center">
                  <span>
                    Copyright © Bhandari Brothers , Developed by{" "}
                    <a href>Creatu Developers</a>
                  </span>
                </div>
              </div>
            </div>
            {/* Row end */}
            <div
              id="back-to-top"
              data-spy="affix"
              data-offset-top={10}
              className="back-to-top position-fixed"
            >
              <button className="btn btn-primary" title="Back to Top">
                <i className="fa fa-angle-double-up" />
              </button>
            </div>
          </div>
          {/* Container end */}
        </div>
        {/* Copyright end */}
      </footer>
      {/* Footer end */}
    </>
  );
};
export default Footer;
