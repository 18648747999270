import axios from "axios";
export const Footerapi = {
  workinghr: function () {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-about-us`,
      method: `GET`,
    });
  },
  navbar: function () {
    return axios.request({
      url: `${process.env.REACT_APP_API_URL}/get-contact`,
      method: `GET`,
    });
  },
};
