import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../Pages/Home";
import About from "../Pages/About/About";
import Servicelistpage from "../Pages/Service/Servicelistpage";
import Servicedetail from "../Pages/Service/Servicedetail";
import Clientpage from "../Pages/Clientpage";
import Jobpage from "../Pages/Job/Jobpage";
import CV from "../Pages/Cv/CV";
import Recuitmentpage from "../Pages/Recuitment/Recuitmentpage";
import Testimonialpage from "../Pages/Testimonialpage";
import Bloglist from "../Pages/Blog/Bloglist";
import Blogdetail from "../Pages/Blog/Blogdetail";
import Contact from "../Pages/Contact/Contact";
function Homerouting() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/service-list" element={<Servicelistpage />} />
      <Route exact path="/service-detail/:slug" element={<Servicedetail />} />
      <Route exact path="/client" element={<Clientpage />} />
      <Route exact path="/jobs" element={<Jobpage />} />
      <Route exact path="/drop-cv" element={<CV />} />
      <Route exact path="/recuitment-service" element={<Recuitmentpage />} />
      <Route exact path="/testimonial" element={<Testimonialpage />} />
      <Route exact path="/blog-list" element={<Bloglist />} />
      <Route exact path="/blog-detail/:slug" element={<Blogdetail />} />
      <Route exact path="/contact" element={<Contact />} />
    </Routes>
  );
}

export default Homerouting;
