import React from "react";
import { Aboutapi } from "./Aboutapi";

import { useEffect, useState } from "react";

import parse from "html-react-parser";
const About = () => {
  const [aboutus, setAboutus] = useState({});
  const [loading, setLoading] = useState("");
  useEffect(() => {
    setLoading(true);
    Aboutapi.about()
      .then((res) => {
        console.log(res.data.data.about, "about us");
        let response = res.data.data.about;
        setAboutus(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div>
        <section id="main-container" className="main-container">
          <div className="container">
            {loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden"></span>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-12">
                  <h3 className="column-title">About us</h3>
                  {/* <p>{aboutus.description ? parse(aboutus.description) : null}</p> */}
                  <p>
                    {aboutus?.description ? parse(aboutus?.description) : null}
                  </p>
                </div>
                {/* Col end */}
                {/* <div className="col-lg-6 mt-5 mt-lg-0">
                  <div id="page-slider" className="page-slider small-bg">
                    <div
                      className="item"
                      style={{
                        backgroundImage: `url(${
                          aboutus?.image ? aboutus.image : "no images"
                        })`,
                      }}
                    ></div>
                  </div>
                 
                </div> */}
              </div>
            )}
            {/* Content row end */}
          </div>
          {/* Container end */}
        </section>
        {/* Main container end */}
        <section id="facts" className="facts-area dark-bg">
          <div className="container">
            <div className>
              <div className="row">
                <div className="col-md-12 col-sm-12 ts-facts">
                  <div className="ts-facts-content">
                    <h3 className="ts-facts-num">
                      <span>Our Mission</span>
                    </h3>
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      </div>
                    ) : (
                      <p className="ts-facts-title">
                        {aboutus?.our_mission
                          ? parse(aboutus.our_mission)
                          : null}
                      </p>
                    )}
                  </div>
                </div>
                {/* Col end */}
                <div className="col-md-12 col-sm-12 ts-facts">
                  <div className="ts-facts-content">
                    <h3 className="ts-facts-num">
                      <span>Our Vision</span>
                    </h3>
                    {loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden"></span>
                        </div>
                      </div>
                    ) : (
                      <p className="ts-facts-title">
                        {aboutus?.our_vision ? parse(aboutus.our_vision) : null}
                      </p>
                    )}
                  </div>
                </div>
                {/* Col end */}
                <div className="col-md-12 col-sm-12 ts-facts">
                  <div className="ts-facts-content">
                    <h3 className="ts-facts-num">
                      <span>Our Goals</span>
                    </h3>
                    <p className="ts-facts-title">
                      {aboutus?.our_goal ? parse(aboutus.our_goal) : null}
                    </p>
                  </div>
                </div>
                {/* Col end */}
              </div>{" "}
              {/* Facts end */}
            </div>
            {/*/ Content row end */}
          </div>
          {/*/ Container end */}
        </section>

        {/* Facts end */}
      </div>
    </>
  );
};
export default About;
